import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started/"
        }}>{`Getting started`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/"
        }}>{`Blocks library`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/controls/"
        }}>{`Controls`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides"
        }}>{`Guides`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/writing-a-custom-block/"
            }}>{`Custom blocks`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about/"
        }}>{`About`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/vision/"
        }}>{`Vision`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced/"
        }}>{`Advanced`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/advanced/how-it-works/"
            }}>{`How it works`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/advanced/transforms/"
            }}>{`Transforms`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/advanced/queries/"
            }}>{`Queries`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/advanced/blocks-docs-generation/"
            }}>{`Blocks docs generation`}</a></li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      